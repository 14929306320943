import React from 'react';
import homeScreenshot from '@studio/assets/images/home.png';
import { StudioMeta, type StudioMetaProps } from '@studio/components/common/meta';
import { StudioLogo } from '@studio/components/common/studio-logo';

import * as SessionComponents from '@blockworks/session/components';

type AuthLayoutProps = Omit<SessionComponents.AuthLayoutProps, 'components'> & {
    metaProps: StudioMetaProps;
};

const AuthLayout = ({ children, metaProps = {}, sidebar = {}, ...rest }: AuthLayoutProps) => {
    return (
        <SessionComponents.AuthLayout
            components={{
                head: <StudioMeta {...metaProps} />,
                logo: <StudioLogo />,
            }}
            sidebar={{ image: homeScreenshot.src, ...sidebar }}
            {...rest}
        >
            {children}
        </SessionComponents.AuthLayout>
    );
};

export { AuthLayout };
