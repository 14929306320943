import { AuthLayout } from '@studio/layouts/auth';

import { SignIn } from '@blockworks/session/components';

const SignInPage = () => {
    return <SignIn center flexGrow={1} />;
};

SignInPage.getLayout = (page: React.ReactElement, metaProps: any) => (
    <AuthLayout
        headingText="Sign in to your account"
        subheadingText="Crypto's most powerful research platform."
        metaProps={metaProps}
    >
        {page}
    </AuthLayout>
);

export const getServerSideProps = async () => {
    return {
        props: {
            meta: {
                title: 'Sign In | Blockworks Studio',
            },
        },
    };
};

export default SignInPage;
